.pregame-nav-container {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding-top: 10px;
  .pregame-nav {
    display: flex;

    .link {
      flex: 1;
      text-align: center;
      cursor: pointer;
      padding: 15px 0;
      color: $medium-text-grey;
      text-decoration: none;
      border-bottom: 1.5px solid $line-color;

      .link-text {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 20px;
          margin-right: 15px;
        }
      }
    }

    .link:hover {
      color: black;
      border-color: #bababa;
    }
  }
  .nav-buttons {
    padding-top: 15px;
    text-align: right;
    user-select: none;
    .nav-btn {
      color: black;
      text-decoration: none;
    }
    .nav-btn:not(.nav-btn--disabled):hover {
      text-decoration: underline;
    }
    .nav-btn:not(:last-child) {
      margin-right: 15px;
    }
    .nav-btn--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.game-started-nav-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding-top: 20px;
  .game-started-nav {
    border-bottom: 1.5px solid $line-color;
    position: relative;

    .link {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      padding: 15px 30px;
      color: $medium-text-grey;
      text-decoration: none;

      .link-text {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 20px;
          margin-right: 15px;
        }
      }
    }

    .active-underline {
      position: absolute;
      left: 0;
      bottom: -2.5px;
      width: 100px;
      height: 3px;
      border-radius: 5px;
      background-color: $main-color;
      transition: ease-in-out .2s;
    }

    .link:nth-child(1).active ~ .active-underline {
      left: 0;
      width: 96px;
    }
    .link:nth-child(2).active ~ .active-underline {
      left: 96px;
      width: 104px;
    }
    .link:nth-child(3).active ~ .active-underline {
      left: 200px;
      width: 140px;
    }
    .link:nth-child(4).active ~ .active-underline {
      left: 340px;
      width: 149px;
    }

    .link:hover {
      color: black;
      border-color: #bababa;
    }
  }
}
