.auth-page {
  width: 100%;
  min-height: 100vh;
  background-color: #151515;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-container {
    padding: 60px 0;
    padding-bottom: 80px;
    width: 600px;
    color: white;
    background-color: #232323;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    border-radius: 6px;
    h1 {
      font-size: 25px;
      margin-bottom: 40px;
    }
    form {
      width: 400px;
      input {
        background-color: #232323;
        color: white;
        border: 1px solid #4d4d4d;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 20px;
        font-size: 14px;
        &::placeholder {
          color: #727272;
        }
      }
      a {
        color: white;
        text-decoration: underline;
      }
      .submit-btn {
        background-color: $main-color;
        color: white;
        width: 100%;
        padding: 12px 0;
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 20px;
        &:not(:valid) {
          cursor: default;
          opacity: 0.3;
        }
      }
      .form-error {
        text-align: center;
        margin-top: -20px;
        margin-bottom: 20px;
        color: #c74141;
      }
    }
    .alt-auth-prompt {
      font-size: 14px;
      a {
        font-size: 14px;
        color: $main-color;
        text-decoration: underline;
      }
    }
  }
  &.reset-password-page {
    .form-container {
      text-align: center;
    }
    input.reset-password-input {
      background-color: #232323;
      color: white;
      border: 1px solid #4d4d4d;
      width: 300px;
      font-size: 14px;
      margin-bottom: 10px;
      &::placeholder {
        color: #727272;
      }
    }
    button {
      padding: 12px 20px;
      width: 300px;
      font-size: 14px;
      background-color: $main-color;
      color: white;
      &:disabled {
        opacity: .5;
        cursor: default;
      }
    }
    .success-text {
      width: 350px;
      color: white;
      font-size: 18px;
      margin-bottom: 40px;
    }
  }
  .mobile-warning {
    display: none;
    color: white;
    font-size: 20px;
    padding: 0 20px;
    text-align: center;
  }

  @media (max-width: $breakpoint-tablet) {
    .form-container {
      display: none;
    }
    .mobile-warning {
      display: block;
    }
  }
}
