// Sass variables

$main-bg-color: #f5f6fc;
$dark-blue-bg: #252737;

$main-color: #ff5c32;
$error-color: #c52121;

$green: #00b278;
$light-blue: #26cfff;
$dark-purple: #6426ff;
$purple: #bd1ae6;

$medium-text-grey: #7e7e7e; // #595959
$light-text-grey: #b9b9b9;
$line-color: #d2d2d2;
$input-color: #f6f6f6;
$input-focus-color: #f1f1f1;

$main-border-width: 2px;
$main-border-radius: 4px;
$pregame-header-height: 51px;

$breakpoint-tablet: 768px;