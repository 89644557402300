.checkout-page {
  .modal__box.default-modal {
    width: 400px;
    text-align: left;
    * {
      text-align: left;
    }
  }
  h2 {
    margin-bottom: 30px;
  }
  .payment-section {
    table.payment-info {
      border: none;
      width: 280px;
      margin-bottom: 15px;
      * {
        box-sizing: content-box;
      }
      tbody {
        tr {
          td {
            height: 25px;
            border: none;
            border-spacing: 0;
          }
          td:first-child {
            color: $medium-text-grey;
          }
          td:last-child {
            font-weight: bold;
          }
        }
        tr.discount > td:last-child {
          color: $green;
          span {
            font-weight: 500;
            color: $medium-text-grey;
            margin-left: 5px;
          }
        }
        tr:last-child > td {
          padding-top: 15px;
        }
      }
    }
    .payment-btn {
      margin-bottom: 20px;
    }
    .bottom-info {
      color: $light-text-grey;
    }
    .success-p {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .checkmark-icon {
        width: 20px;
        margin-right: 10px;
        path {
          fill: $green;
        }
      }
    }
    .free-game-p {
      margin-bottom: 20px;
    }
  }

  .discount-code-section {
    input {
      padding: 9px 20px;
      margin-right: 10px;
    }
  }

  .start-game-section {
    .pre-payment-info {
      color: $medium-text-grey;
      margin-bottom: 20px;
    }
  }
}
