.dashboard-header {
  background-color: white;
  box-shadow: 0 2px 5px rgba(black, 0.1);
  padding: 8px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;

  .account-icon {
    height: 35px;
    user-select: none;
    border-radius: 50px;
    transition: linear 0.2s;

    &:hover {
      box-shadow: 0 0 0 2px rgba(black, 0.1);
    }
  }
}

.dashboard-header.pregame {
  .logo {
    height: 25px;
  }
}

.dashboard-header.game-started {
  .logo {
    height: 26px;
    padding-bottom: 5px;
  }
}

.dashboard-header.basic {
  .logo {
    height: 26px;
    padding-bottom: 5px;
  }
  .actionsMenu {
    display: flex;
    align-items: center;
    .orgName {
      font-weight: bold;
      font-size: 12px;
      margin-right: 15px;
    }
  }
}
