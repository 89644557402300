.rules-display {
  max-width: 500px;
  .section {
    margin-bottom: 50px;
    ul {
      padding-inline-start: 25px;
      list-style: none;

      li {
        position: relative;
        margin-bottom: 10px;
      }
      li:before {
        content: "•";
        font-size: 22px;
        line-height: 15px;
        color: black;
        position: absolute;
        left: -25px;
      }
    }
  }
  .section.updated {
    animation: updated 1.5s;

    @keyframes updated {
      10% {
        color: black;
        background-color: white;
        box-shadow: 0 0 0 20px white;
      }
      30% {
        color: $green;
        background-color: rgba($green, 0.1);
        box-shadow: 0 0 0 20px rgba($green, 0.1);
      }
      100% {
        color: black;
        background-color: white;
        box-shadow: 0 0 0 20px white;
      }
    }
  }
}
.rules-overlay {
  padding-right: 100px;
  .rules-container {
    max-width: 500px;
    margin: auto;
    a.edit-btn-wrapper {
      margin-bottom: 40px;
      display: inline-block;
    }
  }
}

.rules-page {
  display: flex;
  justify-content: center;
  a.edit-btn-wrapper {
    margin-bottom: 40px;
    display: inline-block;
  }
  .rules-container {
    display: inline-block;
    padding: 50px 40px;
    min-width: 580px;
  }
}

.edit-rules-overlay {
  position: fixed;
  overflow: scroll;
  z-index: 150;
  top: $pregame-header-height;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: 40px;
  border-top: 1px solid $line-color;
  background-color: white;
  display: flex;
  justify-content: center;
  .edit-rules-display {
    margin-right: 15%;
    width: 500px;
    .section {
      margin-bottom: 50px;
      position: relative;
      &:last-child {
        margin-bottom: 150px;
      }
      h6 {
        margin-bottom: 15px;
      }
      h2 {
        border-radius: $main-border-radius;
        background-color: $input-color;
        padding: 13px 20px;
        margin-bottom: 30px;
        width: 280px;
        outline: none;
        &:focus {
          background-color: $input-focus-color;
        }
      }
      button.delete-btn {
        background: none;
        color: $error-color;
        position: absolute;
        top: 40px;
        right: 0;
        &:hover {
          text-decoration: underline;
        }
      }
      ul {
        background-color: $input-color;
        padding: 20px;
        padding-inline-start: calc(15px + 20px);
        border-radius: $main-border-radius;
        outline: none;
        &:focus {
          background-color: $input-focus-color;
        }

        li {
          position: relative;
          margin-bottom: 10px;
        }
        // li:before {
        //   content: "•";
        //   font-size: 22px;
        //   line-height: 15px;
        //   color: black;
        //   position: absolute;
        //   left: -16px;
        // }
      }
    }
  }
  .controls-container {
    position: sticky;
    top: 0px;
    .main-btn {
      margin-bottom: 20px;
      &.discard-btn {
        border: none;
        padding: 0;
        svg {
          max-height: 11px;
        }
        &:hover {
          color: $error-color;
          * {
            fill: $error-color;
          }
        }
      }
    }
  }
}
