.players-page {
  .modal__box.default-modal {
    height: 400px;
    text-align: left;
    width: 450px;
    .response-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex-direction: column;
      gap: 20px;
      text-align: center;
    }
    .loading-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 10px;
    }
    .edit-player-container {
      h2 {
        margin-bottom: 30px;
      }
      label {
        font-weight: 600;
        color: $medium-text-grey;
        display: block;
        font-size: 10px;
      }
      .delta-counter {
        font-weight: 500;
        margin-left: 10px;
        &.increase {
          color: $green;
        }
        &.decrease {
          color: $error-color;
        }
      }
      .input-column-section {
        display: flex;
        gap: 15px;
        margin-bottom: 20px;
        .input-column {
          flex: 1;
          label {
            margin-bottom: 5px;
          }
          input {
            width: 100%;
            padding: 8px 12px;
          }
        }
      }
      .points-section {
        margin-bottom: 20px;
        label {
          margin-bottom: 5px;
        }
        .points-counter {
          font-weight: bold;
          width: 60px;
          font-size: 13px;
        }
        .points-counter-input {
          display: block;
          width: 17px;
          height: 35px;
          border-radius: 100px;
          position: relative;
          overflow: hidden;
          button {
            display: flex;
            width: 100%;
            height: 50%;
            padding: 0;
            background-color: $input-color;
            align-items: center;
            justify-content: center;
            border-radius: 0;
            cursor: default;
            &:last-child > * {
              transform: rotate(180deg);
            }
            &:hover {
              background-color: darken($input-color, 4%);
            }
            &:active {
              background-color: darken($input-color, 8%);
            }
          }
        }
      }
      .lives-section {
        margin-bottom: 20px;
        label {
          margin-bottom: 10px;
        }
        .lives-counter {
          font-weight: bold;
          width: 30px;
          display: inline-block;
        }
        input {
          -webkit-appearance: none;
          appearance: none;
          width: 90px;
          height: 6px;
          padding: 0 5px;
          border-radius: 100px;
          background: rgba(#eaeaea, 0.6);
          outline: none;
          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 10px;
            height: 10px;
            background: $main-color;
            border-radius: 100%;
            cursor: pointer;
          }
          &:hover {
            background: #eaeaea;
          }
        }
      }
      .buttons-container {
        padding-top: 20px;
        .main-btn {
          display: inline-block;
          &.discard-btn {
            border: none;
            padding: 0;
            margin-left: 25px;
            svg {
              max-height: 11px;
            }
            &:hover {
              color: $error-color;
              * {
                fill: $error-color;
              }
            }
          }
        }
        .error-text {
          color: $error-color;
          height: 0;
          margin-top: 8px;
          margin-bottom: -8px;
          font-weight: 500;
        }
      }
    }
  }
  .actions-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .search-field-wrapper {
      position: relative;
      .search-field {
        background-color: white;
        border: $main-border-width solid $line-color;
        padding: 7px 12px;
        padding-left: 40px;
        width: 250px;
      }
      .search-icon {
        width: 14px;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 15px;
      }
    }
  }
  .no-search-results {
    text-align: center;
    padding-top: 20px;
  }
}
