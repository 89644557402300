// Reusable basic elements

.error-message {
  color: $error-color;
  font-weight: 500;
  margin-top: 10px;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-top-color: #333; /* Make the top of the spinner a different color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Learn more link

.learn-more-link {
  text-decoration: underline;
  color: $medium-text-grey;
  cursor: pointer;
  display: inline-block;
}

.learn-more-link:hover {
  color: black;
}

.learn-more-icon {
  display: inline-block;
}
