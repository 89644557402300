// Global styles

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// @font-face {
//   font-family: "Montserrat";
//   font-weight: "bold";
//   font-style: normal;
//   src: url("../assets/fonts/Montserrat-Bold.ttf");
// }
// @font-face {
//   font-family: "Montserrat";
//   font-weight: normal;
//   font-style: normal;
//   src: url("../assets/fonts/Montserrat-Medium.ttf");
// }

body {
  background-color: $main-bg-color;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::-webkit-scrollbar {
    display: none;
  }
}

* {
  box-sizing: border-box;
  font-family: "Montserrat", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 17px;
  font-weight: 700;
}

h2 {
  font-size: 14px;
  font-weight: 700;
}

h6 {
  text-transform: uppercase;
  font-size: 10px;
  color: $light-text-grey;
  font-weight: 600;
}

select {
  outline: none;
  option {
    outline: none;
  }
}

input,
textarea {
  background-color: $input-color;
  outline: none;
  border-radius: $main-border-radius;
  border: none;
  padding: 12px 20px;
}

textarea {
  padding: 15px 20px;
}

input::placeholder,
textarea::placeholder {
  color: $light-text-grey;
}

input:focus,
textarea:focus {
  background-color: $input-focus-color;
}

textarea {
  resize: none;
}

[contentEditable="true"]:empty:not(:focus):after {
  content: attr(data-ph);
  color: $light-text-grey;
  cursor: text;
}

button {
  cursor: pointer;
  outline: none;
  border-radius: $main-border-radius;
  border: none;
  user-select: none;
}

a {
  text-decoration: none;
  display: inline-block;
}

img {
  user-select: none;
}
