.full-page-loader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(white, .95);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    z-index: 10000;

    .loading-icon {
        font-size: 16px;
        padding-bottom: 100px;
    }
}