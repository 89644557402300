.onboarding-page {
  text-align: center;
  background-color: white;
  min-height: 100vh;

  .watermark-logo {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
  }

  .primary-button {
    background: none;
    color: $green;
    font-size: 14px;
    border: 1.5px solid $green;
    padding: 12px 30px;
    border-radius: $main-border-radius;
    text-decoration: none;
  }
  .primary-button:not([disabled]):hover {
    background-color: $green;
    color: white;
  }
  .primary-button[disabled] {
    opacity: 0.3;
    cursor: default;
  }

  .secondary-button {
    background: none;
    border: none;
    font-size: 14px;
    color: $medium-text-grey;
    padding: 12px 30px;
    margin-top: 10px;
  }

  .secondary-button:hover {
    background-color: rgba(black, 0.05);
  }

  h1 {
    font-size: 30px;
    font-weight: 500;
    color: black;
  }

  h3 {
    font-size: 22px;
    font-weight: 500;
    color: $medium-text-grey;
  }

  .first-step {
    padding-top: 180px;

    h1 {
      margin-bottom: 120px;
    }

    h3 {
      margin-bottom: 30px;
    }
  }

  .second-step {
    padding-top: 60px;

    h1 {
      margin-bottom: 50px;
    }

    .card-container {
      display: flex;
      justify-content: center;
      align-items: stretch;
      width: 80%;
      max-width: 1100px;
      margin: auto;

      .card {
        max-width: 300px;
        box-shadow: 0 3px 99px rgba(black, 0.1);
        border-radius: 5px;
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 30px 0;
        cursor: pointer;
        transition: 0.05s all ease-in-out;

        .card-img {
          width: 80%;
          margin: auto;
          margin-bottom: 50px;
        }

        .card-label {
          font-size: 18px;
        }
      }

      .card:hover {
        transform: scale(1.02);
      }
    }
  }
  .third-step {
    padding-top: 60px;

    .form-container {
      margin-bottom: 60px;

      h1 {
        margin-bottom: 50px;
      }

      .input-wrapper {
        position: relative;
        width: 95%;
        max-width: 400px;
        margin: auto;
        margin-bottom: 20px;

        input,
        select {
          background: none;
          border: none;
          border-bottom: 1.5px solid $line-color;
          border-radius: 0;
          padding: 18px 10px 12px 10px;
          width: 100%;
          font-size: 14px;
        }

        select {
          cursor: pointer;
        }

        label {
          position: absolute;
          color: $light-text-grey;
          font-size: 14px;
          transform: translateY(-50%);
          top: 50%;
          left: 10px;
          cursor: text;
          transition: 0.08s ease-in-out all;
          user-select: none;
        }

        input:not(:placeholder-shown) + label,
        input:focus + label {
          top: 5px;
          font-size: 10px;
        }
      }
      .form-error {
        color: $error-color;
      }
    }
  }
  .fourth-step {
    padding-top: 220px;

    h1 {
      margin-bottom: 60px;
    }
  }
}
