.setup-page {
  .main-setup-container {
    h1 {
      margin-bottom: 40px;
    }
    .setup-step {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      .step-info-wrapper {
        display: flex;
        .checkmark-icon {
          width: 22px;
          margin: 0 20px 0 10px;
        }
        .setup-step-info {
          h2 {
            margin-bottom: 10px;
          }
          p {
            max-width: 400px;
            color: $medium-text-grey;
          }
        }
      }
      .setup-step-buttons {
        display: flex;
        a:not(:last-child) {
          margin-right: 25px;
        }
      }
    }
    .setup-options {
      h2 {
        margin-bottom: 10px;
      }
      .setup-option {
        display: flex;
        align-items: center;
        h3 {
          margin-right: 5px;
        }
        .learn-more-icon {
          display: block;
          line-height: 1;
          margin-right: 30px;
        }
        .number-input {
          border: 1px solid $line-color;
          background-color: white;
          width: 80px;
          padding: 8px 15px;
        }
      }
    }
  }
}
