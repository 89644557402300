// Reusable layout elements

.dashboard-container {
  padding-top: 20px;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.dashboard-container--game-started {
  padding-top: 40px;
  max-width: 1200px;
}

.dashboard-container--between-games {
  padding-top: 60px;
  .controls-container {
    margin-bottom: 20px;
  }
}

.section-container {
  background-color: white;
  border-radius: $main-border-radius;
  padding: 30px 40px;
  padding-bottom: 60px;
  margin-bottom: 30px;
}

.column-wrapper {
  display: flex;
}

.column {
  flex: 1;
}

.column:not(:last-child) {
  margin-right: 40px;
}
