.player-list {
  table {
    width: 500px;
    border: none;

    &.full-page-width {
      width: 70%;
    }
    thead {
      text-align: left;
      tr {
        th {
          padding-bottom: 15px;
        }
      }
    }
    tbody {
      tr {
        td {
          height: 30px;
          &:not(:first-child) {
            color: $medium-text-grey;
          }
          &.green {
            color: $green;
          }
          &.red {
            color: $error-color;
          }
          div.more-icon-wrapper {
            position: relative;
            div.more-icon {
              width: 25px;
              height: 25px;
              padding: 4px;
              background-color: #f8f8f8;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              &.open {
                background-color: #e8e8e8;
              }
              &.loading {
                justify-content: center;
                cursor: default;
              }
              div.more-icon-bullet {
                width: 4px;
                height: 4px;
                border-radius: 5px;
                background-color: #929292;
              }
            }
            div.more-options-modal {
              position: absolute;
              top: 50%;
              left: 40px;
              transform: translateY(-50%);
              padding: 10px 15px;
              border-radius: 5px;
              background-color: white;
              box-shadow: 0 3px 20px rgba(black, 0.1);
              div.option {
                color: $medium-text-grey;
                font-size: 11px;
                text-align: center;
                margin: 5px 0;
                cursor: pointer;
                &:hover {
                  color: black;
                }
              }
            }
          }
        }
      }
    }
  }
}

.list-overlay {
  h2 {
    margin-bottom: 10px;
  }

  .player-list-amount {
    color: $medium-text-grey;
    margin-bottom: 40px;
    span {
      color: black;
      font-weight: bold;
      margin-left: 6px;
    }
  }
}
