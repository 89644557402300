.games-center-page {
  .section-container {
    h2 {
      margin-bottom: 20px;
    }
  }
  .game-list {
    .game-list-item {
      padding: 15px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ededed;
      .info-container {
        display: flex;
        .date-container {
          display: flex;
          margin-right: 40px;
          p {
            span {
              color: $light-text-grey;
            }

            &:first-child {
              margin-right: 20px;
              font-weight: bold;
              width: 30px;
            }
            &:last-child {
              width: 100px;
            }
          }
        }
      }
      .badge-container {
        display: flex;
        .badge {
          border-radius: 50px;
          padding: 5px 15px;
          font-size: 10px;
          font-weight: bold;
          background-color: $input-color;
          color: $medium-text-grey;
          &:not(:last-child) {
            margin-right: 10px;
          }
          &.green {
            background-color: rgba($green, 0.1);
            color: $green;
          }
        }
      }
    }
  }
  .modal__box.default-modal {
    width: 600px;
    h1 {
      margin-bottom: 10px;
    }
    .modal-info {
      max-width: 250px;
      margin: auto;
      margin-bottom: 40px;
    }
    .content-container {
      text-align: left;
      display: inline-block;
      h6 {
        margin-bottom: 10px;
      }
      input {
        width: 250px;
        margin-bottom: 20px;
      }
      .main-btn {
        display: block;
        margin: auto;
        margin-top: 20px;
      }
    }
  }
}
