.game-settings-page {
  .modal__box.default-modal {
    text-align: left;
    width: 700px;
    .edit-game-options-container {
      p {
        font-size: 12px;
        text-align: left;
        margin-bottom: 0;
      }
      h2 {
        margin-bottom: 30px;
      }
      table.edit-game-options-table {
        margin-bottom: 40px;
        tr {
          height: 30px;
          td:first-child {
            padding-right: 50px;
          }
          td:last-child {
            font-weight: bold;
            .new-targets-counter {
              font-weight: bold;
              width: 30px;
              display: inline-block;
            }
            input {
              -webkit-appearance: none;
              appearance: none;
              width: 90px;
              height: 6px;
              padding: 0 5px;
              border-radius: 100px;
              background: rgba(#eaeaea, .6);
              outline: none;
              &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 10px;
                height: 10px;
                background: $main-color;
                border-radius: 100%;
                cursor: pointer;
              }
              &:hover {
                background: #eaeaea;
              }
            }
            .option-info {
              color: #9c9c9c;
              display: inline-block;
              padding: 3px 8px;
              font-weight: 600;
              font-size: 9px;
              border-radius: 4px;
              text-transform: uppercase;
              background-color: #f6f6f6;
            }
          }
        }
      }
      .explainer-text {
        width: 260px;
        margin-top: 25px;
        font-size: 11px;
        color: $medium-text-grey;
      }
      .button-container {
        display: flex;
        margin-bottom: 10px;
        .discard-btn {
          border: none;
          padding: 0;
          margin-left: 25px;
          svg {
            max-height: 11px;
          }
          &:hover {
            color: $error-color;
            * {
              fill: $error-color;
            }
          }
        }
      }
    }
  }
  .column.column--right {
    max-width: 400px;
  }
  .section-container {
    padding-bottom: 30px;
    .game-rules-container {
      display: flex;
      align-items: center;
      .game-rules-info {
        flex: 1;
        h2 {
          margin-bottom: 5px;
        }
        p {
          color: $medium-text-grey;
        }
      }
      .game-rules-buttons {
        display: flex;
        a:not(:last-child) {
          margin-right: 25px;
        }
      }
    }
    .game-options-container {
      .game-options-header {
        h2 {
          margin-bottom: 10px;
          display: inline-block;
        }
        button {
          float: right;
        }
      }
      table.game-options-table {
        width: 180px;
        tr {
          height: 25px;
          td:first-child {
            color: $medium-text-grey;
            padding-right: 20px;
          }
          td:last-child {
            font-weight: bold;
          }
        }
      }
    }
  }
}
