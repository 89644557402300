.support-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: $main-color;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 3px 10px rgba($main-color, 0.5);
  transition: all 0.15s ease-in-out, transform 0.15s ease-in-out 0.3s;

  * {
    width: 20px;
    height: 20px;
    fill: white;
  }

  &:hover {
    transition-delay: 0s;
    box-shadow: 0 3px 10px rgba($main-color, 0.9);
    // transform: translateY(-2px);
    margin-bottom: 2px;
  }

  &.modal-open {
    transition-delay: 0s;
    transform: translate(80px, -2px);
  }
}

.support-modal {
  position: fixed;
  height: 100vh;
  width: 480px;
  padding: 60px 40px;
  background-color: white;
  top: 0;
  right: 0;
  z-index: 1000;
  box-shadow: -3px 0 10px rgba(black, 0.05);
  transform: translateX(100%);
  transition: ease-in-out 0.3s;

  &.modal-open {
    transition-delay: 0.1s;
    transform: translateX(0);
  }

  .close-icon {
    position: absolute;
    right: 40px;
    top: 30px;
    width: 15px;
    cursor: pointer;
  }

  .heading-container {
    display: flex;
    margin-bottom: 30px;
    align-items: center;

    .icon-badge {
      height: 50px;
      width: 50px;
      border-radius: 50px;
      background-color: $main-color;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      * {
        width: 20px;
        height: 20px;
        fill: white;
      }
    }

    h2 {
      font-size: 25px;
    }
    p {
      color: $medium-text-grey;
    }
  }
  form {
    width: 400px;

    label {
      font-weight: bold;
      display: block;
      padding-bottom: 5px;
    }

    input,
    textarea {
      width: 100%;
      margin-bottom: 20px;
    }

    .main-btn {
      margin-bottom: 10px;
    }

    .submitting-message {
      margin-top: 20px;
      font-size: 14px;
    }

    .error-message {
      color: $error-color;
    }
  }

  .success-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 60px;
    .success-badge {
      height: 80px;
      width: 80px;
      border-radius: 50px;
      background-color: $green;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      * {
        width: 50%;
        height: 50%;
        fill: white;
      }
    }
    h2 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 60px;
    }
  }
}
