.overlay-page {
  position: fixed;
  overflow: scroll;
  z-index: 1000;
  top: $pregame-header-height;
  left: 0;
  bottom: 0;
  right: 0;
  padding-left: 100px;
  padding-top: 40px;
  border-top: 1px solid $line-color;
  background-color: white;
  // animation: fade 0.05s;
  .close-icon {
    position: fixed;
    top: calc(40px + 51px); // $pregame-header-height gives error on build script
    left: 40px;
    width: 15px;
    cursor: pointer;
  }
  .close-icon:active {
    transform: scale(0.9);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
