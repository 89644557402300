.game-events-page {
  .game-events-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-rows: 2fr 1fr;
    gap: 60px;
    margin-bottom: 60px;
    .game-event-card {
      background-color: white;
      color: black;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      padding: 40px;
      text-align: center;
      box-shadow: 0 3px 10px rgba($color: black, $alpha: 0.03);
      .card-content {
        margin-bottom: 40px;
        h2 {
          margin-bottom: 10px;
        }
      }
      &.placeholder {
        padding: 0;
        justify-content: center;

        p {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
        }
      }
      &.featured {
        padding-top: 10px;
        .img-wrapper {
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 50px;
          }
        }
      }
      &.shuffle-targets {
        color: white;
        background: linear-gradient($light-blue, #0078f0);
        box-shadow: 0 3px 20px rgba($color: $light-blue, $alpha: 0.4);
      }
      &.double-points {
        color: white;
        background: linear-gradient($green, #008548);
        box-shadow: 0 3px 20px rgba($color: $green, $alpha: 0.4);
      }
      &.revenge-of-the-fallen {
        color: white;
        background: linear-gradient($dark-purple, #5200BE);
        box-shadow: 0 3px 20px rgba($color: $dark-purple, $alpha: 0.4);
      }
      &.force-catches {
        color: white;
        background: linear-gradient($purple, #A00095);
        box-shadow: 0 3px 20px rgba($color: $purple, $alpha: 0.4);
      }
    }
  }
  .section-container {
    h2 {
      margin-bottom: 20px;
    }
    .main-btn--error {
      margin-bottom: 10px;
    }
  }
  .coming-soon-container {
    padding: 60px 0;
    .coming-soon {
      font-size: 20px;
      text-align: center;
    }
  }
}
