.player-setup {
  h2 {
    margin-bottom: 30px;
  }
  .invite-container {
    .invite-form {
      form {
        .input-wrapper {
          margin-bottom: 20px;

          input {
            display: block;
            width: 100%;
          }
        }
        .main-btn {
          margin-bottom: 10px;
        }
      }
    }
  }
  .guide-container {
    ul.guide-list {
      padding-inline-start: 30px;
      list-style: none;

      li {
        color: $medium-text-grey;
        position: relative;
        margin-bottom: 20px;
      }
      li:before {
        content: "•";
        font-size: 22px;
        line-height: 15px;
        color: black;
        position: absolute;
        left: -25px;
      }
    }
  }
  .player-list-container {
    display: flex;
    padding-bottom: 40px;
    .player-list-info {
      h2 {
        margin-bottom: 10px;
      }
      .player-list-amount {
        color: $medium-text-grey;
        margin-bottom: 20px;
        span {
          color: black;
          font-weight: bold;
          margin-left: 6px;
        }
      }
      .player-list-link {
        color: $light-text-grey;
        text-decoration: underline;
        cursor: pointer;
        display: inline-block;
      }
      .player-list-link:hover {
        color: $medium-text-grey;
      }
    }
    .player-list-info.pending-invites {
      flex: 1.3;
    }
    .player-list-info.joined-players {
      flex: 1;
    }
  }
}
.invite-type-selection {
  .options-container {
    background-color: white;
    border-radius: $main-border-radius;
    padding: 50px 100px;
    margin-top: 10px;

    h1 {
      margin-bottom: 50px;
    }

    .options-wrapper {
      display: flex;
      justify-content: space-between;

      .option-card {
        height: 230px;
        width: 200px;
        cursor: pointer;
        text-align: center;
        border: 1px solid $line-color;
        border-radius: $main-border-radius;
      }
    }
  }
}
