// Main button

.main-btn {
  border: $main-border-width solid $green;
  padding: 7px 16px;
  background-color: white;
  font-weight: bold;
  color: $green;
  position: relative;
}

.main-btn[disabled] {
  opacity: 0.3;
  cursor: default;
}

.main-btn:not([disabled]):not(.main-btn--secondary):not(.main-btn--text):not(
    .main-btn--error
  ):not(.main-btn--white):hover {
  background-color: $green;
  color: white;
  * {
    fill: white;
  }
}

// .main-btn:active {
//   opacity: 0.9;
// }

// .main-btn:not(.main-btn--secondary):not(.main-btn--error):focus {}

// Main button with icon

.main-btn--with-icon {
  display: flex;
  align-items: center;
  padding: 7px 12px;

  * {
    fill: $green;
  }
  svg {
    max-width: 18px;
    max-height: 13px;
    margin-right: 8px;
  }
}

// Main button secondary

.main-btn--secondary {
  border-color: $line-color;
  color: $medium-text-grey;
  * {
    fill: $medium-text-grey;
  }
}

.main-btn--secondary:not([disabled]):hover,
.main-btn--secondary:focus {
  border-color: #bababa;
}

// Main button secondary

.main-btn--text {
  border-color: white;
  color: $medium-text-grey;
  * {
    fill: $medium-text-grey;
  }
}

.main-btn--text:not([disabled]):hover,
.main-btn--text:focus {
  border-color: #e6e6e6;
}

// Main button error

.main-btn--error {
  border-color: $error-color;
  color: $error-color;
  * {
    fill: $error-color;
  }
}

.main-btn--error:not([disabled]):hover {
  background-color: $error-color;
  color: white;
  * {
    fill: white;
  }
}

// Main button white

.main-btn--white {
  border: none;
  color: black;
  * {
    fill: black;
  }
}

.main-btn--white:not([disabled]):hover {
  background-color: rgba($color: white, $alpha: 0.9);
  color: black;
  * {
    fill: black;
  }
}

/* Loading button */

.main-btn[data-loading] {
  color: rgba(white, 0);
  * {
    fill: rgba(white, 0);
  }
}

.main-btn[data-loading]::after {
  content: ""; /* This is necessary for the pseudo-element to work */
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  margin-top: -10px; /* Negative half of width to center */
  margin-left: -10px; /* Negative half of height to center */
  border: 2px solid #ccc;
  border-top-color: #333; /* Make the top of the spinner a different color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
