.modal__page-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.2);
  backdrop-filter: blur(3px);
  animation: fade 0.2s;

  .modal__box {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 500px;
    padding: 40px;
    border-radius: $main-border-radius;
    text-align: center;

    &.default-modal {
      width: 900px;
      top: 40%;
    }

    &.info-modal {
      width: 700px;
    }
    .close-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 16px;
      cursor: pointer;
    }
    .info-icon {
      font-family: serif;
      font-weight: bold;
      font-size: 40px;
      line-height: 40px;
      user-select: none;
      width: 50px;
      height: 50px;
      border: 4px solid black;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      margin-bottom: 30px;
    }
    h1 {
      text-align: center;
      margin-bottom: 20px;
    }
    p {
      font-size: 13px;
      text-align: center;
      margin-bottom: 40px;
    }
    .main-btn {
      margin-bottom: 0;
    }
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
