.game-overview-page {
  .column--left {
    max-width: 400px;
  }
  .section-container {
    h2 {
      margin-bottom: 20px;
    }
    .stats-container {
      position: relative;
      table.game-stats-table {
        tr {
          height: 25px;
          td:first-child {
            color: $medium-text-grey;
            padding-right: 20px;
          }
          td:last-child {
            font-weight: bold;
          }
        }
      }
      .progress-ring-wrapper {
        position: absolute;
        right: 0;
        top: -20px;
        display: inline-block;
        svg.progress-ring {
          circle:first-child {
            stroke: #f3f3f3;
          }
          circle:last-child {
            stroke: $main-color;
          }
        }
        .progress-text-box {
          text-align: center;
          position: absolute;
          top: 47%;
          left: 50%;
          transform: translate(-50%, -50%);

          .progress-number {
            font-size: 16px;
            font-weight: bold;
          }
          .progress-text {
            font-size: 10px;
            color: $medium-text-grey;
          }
        }
      }
    }
    .game-message-form {
      textarea {
        width: 100%;
        margin-bottom: 20px;
      }
      .error-message {
        color: $error-color;
        margin-top: -10px;
        margin-bottom: 20px;
      }
      .main-btn {
        margin-bottom: 10px;
      }
    }
    .game-log-container {
      .daysWithoutActivity {
        float: right;
        margin-top: -40px;
        color: $light-text-grey;
      }
      .game-log {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .timestamp {
          width: 120px;
          .time {
            font-size: 10px;
            color: $light-text-grey;
            text-transform: uppercase;
          }
        }
        .simple-log-text {
          color: $medium-text-grey;
        }
        .catch-text-container {
          display: flex;
          .catcher-name {
            color: $green;
          }
          .caught-text {
            color: $medium-text-grey;
            margin: 0 20px;
          }
          .target-name {
            color: $error-color;
          }
        }
      }
      .no-activity-text {
        color: $medium-text-grey;
      }
    }
  }
}
